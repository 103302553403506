.display-block{
    display: block;
}

.display-none{
    display: none;
}

.caseID-text{
    color: blue;
    cursor: pointer;
    border: 0px;
}
.caseID-text:hover{
    background-color: transparent;
    color: blue;
}

.textVerticallyCenter{
    justify-content: center;
    align-items: center;
    display: flex;
}

.cursorPointer{
    border: none;
}

.cursorPointer:hover{
    cursor: pointer;
}

.ActionButtonBlue{
    background-color: #0469E3;
    border-radius: 16px;
    height: 32px;

    color: #ffffff;
}

.ActionButtonBlue:hover{
    cursor: pointer;
}

.ActionGroup_CursorPointer button:hover{
    cursor: pointer;
}

.Content__Text--textWrap{
    word-wrap: break-word;
}

.case_description{
    border: none;
    margin: -10px;
    padding: 0px;
    background-color: transparent !important;
    color: blue !important;
}

.case_description:hover{
    background-color: transparent !important;
    color: blue !important;
    cursor: pointer;
}
.custom-table .hm005a_spectrum-Table-headWrapper{
    border-radius: 10px 10px 0px 0px;
    border:1px solid #B1B1B1;
  }
  .custom-table .hm005a_spectrum-Table-headCell{
    background-color: #F8F8F8 ;
  } 
  .pointer{
    cursor: pointer !important;
  }
  .pointer .ntVziG_spectrum-ActionButton{
    cursor: pointer !important;
    border-radius: 20px;
    background-color: #E6E6E6;
    border:none;
    font-weight: 700;
  }
  
