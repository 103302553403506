.actionButton--textonly span{
    padding: 0 !important;
}

.actionButton--textonly:hover{
    background-color: transparent;
    cursor: pointer;
}

.cursor--pointer:hover{
    cursor: pointer;
}

.timeRange--closed{
    color: red;
}

.timeRange--0000-2359{
    color: green;
}

.timeRange--0900-1700{
    color: indigo;
}

.timeRange--0900-1800{
    color: rgb(58, 146, 146);
}

.timeRange--0600-1800{
    color: fuchsia;
}

.timeRange--0800-1700{
    color: slateblue;
}
.timeRange--0800-1800{
    color: rgb(69, 255, 31);
}

.timeRange--other{
    background: black;
}
.statusTimeRange--closed::before{
    background: red;
}

.statusTimeRange--0000-2359::before{
    background: green;
}

.statusTimeRange--0900-1700::before{
    background: indigo;
}

.statusTimeRange--0900-1800::before{
    background: rgb(9, 142, 219);
}

.statusTimeRange--0600-1800::before{
    background: fuchsia;
}

.statusTimeRange--0800-1700::before{
    background: slateblue;
}

.statusTimeRange--0800-1800::before{
    background: rgb(69, 255, 31);
}

.statusTimeRange--other::before{
    background: black;
}
/* statusTimeRange */
/* 9-5
9-6
6-6
8-5 */