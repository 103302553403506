.searchMobile {
    padding: 5px 5px 5px 5px;
    margin-bottom: 20px;
}

.search {
    width: 30%;
    padding-left: 10px;
    text-align: left;
}

._9ragMG_flex-container div {
    justify-content: end;
}

.listItems {
    min-height: 45vh;
    max-height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.boxInside {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
    /* border: 1px solid cyan; */
    border-radius: var(
        --spectrum-textfield-border-radius,
        var(--spectrum-alias-border-radius-regular)
    );
    /* box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2); */
}

.FzVSrW_spectrum-TabsPanel--vertical
    .FzVSrW_spectrum-Tabs
    > .FzVSrW_spectrum-Tabs-item.FzVSrW_is-selected {
    color: var(
        --spectrum-tabs-text-color-selected,
        var(--spectrum-global-color-gray-900)
    );
    background-color: #e0dfdf70;
    height: auto;
}

.color-header {
    background-color: #474747;
    text-align: center;
    /* height: 44px; */
    font-size: 20px;
    color: #f1f1f1;
    padding: 10px 0px;
}

.FzVSrW_spectrum-Tabs--vertical .FzVSrW_spectrum-Tabs-item > span {
    text-wrap: wrap;
}

.FzVSrW_spectrum-Tabs--vertical .FzVSrW_spectrum-Tabs-item {
    height: auto;
}

._0YML2q_spectrum-Modal--fullscreen {
    width: calc(80% - 80px);
    max-width: none;
    height: calc(100% - 80px);
    max-height: 90vh;
    overflow-y: auto;
    position: fixed;
    top: 40px;
    bottom: 40px;
    left: 11%;
    right: 40px;
}
