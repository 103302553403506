.tooltips {
    display: inline-block;
    cursor: pointer;
}

.tooltip-content {
    position: fixed;
    background-color: #474747;
    color: white;
    max-width: 200px;
    padding: 8px;
    border-radius: 4px;
    font-size: 1.2rem;
    z-index: 1000;
    pointer-events: none;
}
.tooltip-content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #474747 transparent transparent transparent;
}
