.searchMobile {
    padding: 5px 5px 5px 5px;
    margin-bottom: 20px;
}

.search {
    width: 30%;
    padding-left: 10px;
    text-align: left
}

/* .spectrum-Textfield-icon_73bc77{
margin-left: 100%;
}

._spectrum-Table-cellWrapper_d9745 {
position: absolute
}

._react-spectrum-Table-cellWrapper_a403a {
    position: absolute
} */

.flex-gap_7684cb {
    justify-content: end !important;
}
/* ._spectrum-Table-cellWrapper_d9745 {
    position: absolute !important;
} */
._spectrum-Table-row_d9745 {
    border-bottom: 2px solid rgb(234 234 234);
}

/* .spectrum-Table-body_d0fc74 {
    min-height: 200px;
} */


.actionButtonTextOverflow{
    width: 100%;
    padding: 0px;
}

button.actionButtonTextOverflow > span.spectrum-ActionButton-label_e2d99e{
    padding: 0px !important;
}

.spectrum-Table-cellContents_d0fc74 > .spectrum-ActionButton_e2d99e > .spectrum-ActionButton-label_e2d99e{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
