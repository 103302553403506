.card {
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    /* box-shadow: 14px 14px 28px #bebebe, -14px -14px 28px #ffffff; */
    height: auto;
    min-height: 40vh;
    min-width: 21vw;
    width: 21vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    margin: 5px;
    background-color: #f9f9f9;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.card:hover {
    transform: scale(1.03);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    border: 1px solid #0569e4;
}

.addBtn {
    border-radius: 6px;
    cursor: pointer;
    width: auto;
    padding: 0px 4px;
    min-height: 25px;
    height: 11px;
}