.App {
  font-family: adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  text-align: center;
}


.spectrum-Accordion {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  width:100%;
}
.spectrum-Accordion {
  --spectrum-accordion-item-title-padding-y: 8px;
  --spectrum-accordion-animation-duration: 130ms;
}
.spectrum-Accordion-item:first-of-type {
  border-top: 1px solid transparent;
}
.spectrum-Accordion-item {
  border-color: rgb(213, 213, 213);
}
.spectrum-Accordion-itemHeader {
  position: relative;
  display: -ms-flexbox;
  /* display: flex; */
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-top: 6px;
  padding-bottom: 10px;
  margin: 0;
  min-height: calc(100% - 1px);
  font-size: 13px;
  line-height: 1.3;
  /* text-transform: uppercase; */
  letter-spacing: calc(0.06em / 100);
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: 500;
  appearance: none;
  background-color: inherit;
  border: 0;
  font-family: inherit;
  width: 100%;
  font-weight: 800 !important;
}
.spectrum-Accordion-itemHeader {
  padding-left: 38px;
}
.spectrum-Accordion-item {
  z-index: inherit;
  position: relative;
  display: list-item;
  margin: 0;
  border-bottom: 1px solid transparent;
}


.spectrum-Accordion-item.is-open > .spectrum-Accordion-itemContent {
  transition: all 1s;
  display: block;
}
.spectrum-Accordion-itemContent {
  padding-top: 0;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  display: none;
  color: rgb(34, 34, 34);
}
.spectrum-Accordion-itemHeading {
  margin: 0;
  position: relative;
  box-sizing: border-box;
}


.spectrum-Accordion-itemHeader {
  text-align: left;
}
.spectrum-Accordion-itemHeader {
  padding-right: 22px;
}


.spectrum-Accordion-itemHeader {
  color: rgb(70, 70, 70);
}


.spectrum-Accordion-item.is-open
  > .spectrum-Accordion-itemHeading
  > .spectrum-Accordion-itemHeading
  > .spectrum-Accordion-itemIndicator {
  transform: rotate(90deg);
}
.spectrum-Accordion-itemIndicator {
  left: 16px;
}
.spectrum-Icon:not(:root),
.spectrum-UIIcon:not(:root) {
  overflow: hidden;
}
.spectrum-Accordion-itemIndicator {
  fill: rgb(109, 109, 109);
}
.spectrum-Accordion-itemIndicator {
  display: block;
  position: absolute;
  top: 13px;
  transition: transform ease var(130ms);
}
.spectrum-Accordion-item.is-open
  > .spectrum-Accordion-itemHeading
  > .spectrum-Accordion-itemHeading
  > .spectrum-Accordion-itemIndicator {
  transform: rotate(90deg);
}




.elipseText{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}


.is-open > div > div > div > .arrowIconButton > svg{
  transition: all 0.5s;
  transform: rotate(90deg);
}


.schedule-label.disabled{
  color: #C5C5C5;
}


.marginleft10{
  margin-left: 10px;
}


.key{
  width:30%;
  max-width:40%;
  margin-left:10px;
  word-break:'break-word';
}


.value{
  width: 30%;
  margin-left: 10px;
}


.pointer{
  cursor: pointer;
}


.box-shadow{
  width: 90%;
  border-radius: 4px;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 3px 1px rgba(0, 0, 0, 0.19); */
  box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
  padding: 10px 10px;
}


.pre {
  display: block;
  font-weight: 500;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #000;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #fcfcfc;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.chevron{
position: absolute;
width: 25px;
top: 0px;
right: 100px;
cursor: pointer;
border-radius: 50%;
background-color: #ededed;
border: 1px solid transparent;
height: 25px;
padding: 3px;
}