.searchMobile {
    padding: 5px 5px 5px 5px;
    margin-bottom: 20px;
}

.search {
    width: 30%;
    padding-left: 10px;
    text-align: left
}

/* .spectrum-Textfield-icon_73bc77{
margin-left: 100%;
}

._spectrum-Table-cellWrapper_d9745 {
position: absolute
}

._react-spectrum-Table-cellWrapper_a403a {
    position: absolute
} */

.flex-gap_7684cb {
    justify-content: end !important;
}
/* ._spectrum-Table-cellWrapper_d9745 {
    position: absolute !important;
}*/
._spectrum-Table-row_d9745 {
    border-bottom: 2px solid rgb(234 234 234);
}

/*.spectrum-Table-body_d0fc74 {
    min-height: 200px;
} */

.spectrum-Table--wrap_d0fc74 .spectrum-Table-cellContents_d0fc74 {
    word-break: break-all;
    white-space: normal;
}

.spectrum-Search-input_d3c0f3{
    padding-left: 20px !important;
}

.spectrum-Table-row_d0fc74 {
    /* border: 1px solid yellow; */
}

/* .react-spectrum-Table-cell_e844a7 {
    display: flex;
    align-items: normal;
} */

/* .spectrum-Table-cellContents_d0fc74 {
    border: 1px solid red;
    min-width: 100%;
    white-space: unset !important;
    text-overflow: unset !important;
    flex: 1 1;
    margin: -4px;
    padding: 4px;
    overflow: hidden;
    height: 100px;
} */

.tooltipButton{
    padding: 0px;
}

.tooltipButton > span {
    padding: 0px !important;
}


#subRulesContainer ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  #subRulesContainer ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  #subRulesContainer ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  #subRulesContainer ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

 /* subrule bader css*/
 .subRuleOrderBadge{
    background-color: gray;
    color: white;
    font-weight: bold;
    font-size: 12px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border: 2px solid black;
    text-align: center;
    padding: 2px;
  }