/* .key-margin label{
    margin-right: 47px;
}

.values-margin label{
    margin-right: 69px;
} */

.action-btn {
    float: inline-end;
}

.array > .object{
    padding-left: 20px;
    margin-left: 20px;
}

.array > .object > .feild{
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 5px;
}

.font-weight{
    font-weight: 900;
}

.center > span{
    text-align: start;
}