.voiceCard {
    margin: 5px auto;
    padding: 10px;
    border: 1px solid #5c555c;
    border-radius: var(--spectrum-textfield-border-radius,var(--spectrum-alias-border-radius-regular));
    /* display: flex; */
    width: 100%;
}

.voiceCard .checkboxSection{
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.innerVoiceCard {
    margin: 0 auto;
    padding: 10px;
    border: 1px solid thistle;
    border-radius: var(--spectrum-textfield-border-radius,var(--spectrum-alias-border-radius-regular));
    display: flex;
    width: 100%;
}

.voiceCard .FormSection{
    width: 80%;
    margin: 0px 10px;
}

.voiceCard .FormSection .feild{
    margin: 10px 0px;
}

.voiceCard .actionsSection{
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.cursor{
    cursor: pointer;
}