.amazon-connect-button {
    align-items: center;
    transition: transform 0.4s ease;
    
}

.amazon-connect-button:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    background-color: rgb(185, 246, 246) !important;
}