.bottom-flex {
    display: flex;
    margin-top: 1%;
    margin-bottom: 1%;
    min-height: 68.75vh;
    flex-direction: row;
}

.login-flex {
    display: flex;
    min-height: 68.75vh;
    align-items: center;
}

.menu-flex {
    font-size: 1.2em;
    font-weight: bold;
    border: 0.5px solid grey;
    border-radius: 0.4em;
    padding: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
}

/*.accessSummaryPage {
  width: 100%;
}*/

.primary-box {
    background-color: #007bff !important;
}

.secondry-box {
    background-color: #6c757d !important;
}

.success-box {
    background-color: #28a745 !important;
}

.danger-box {
    background-color: #dc3545 !important;
}

.warning-box {
    background-color: #ffc107 !important;
}

.info-box {
    background-color: #17a2b8 !important;
}

.flex-container {
    display: flex;
}

.flex-container > div {
    margin: 5px;
    padding: 20px;
}

.search-btn {
    margin-left: 50px;
    margin-top: 4px;
}

.search-btn-search {
    margin-left: 15px;
    margin-top: 15px;
}

.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    float: right;
}

.pagination-list {
    margin: 10px auto;
    max-width: 1100px;
}

.loading-shade {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
}

.record-not-found {
    height: 320px;
    width: 100%;
}

.record-not-found i {
    font-size: 70px;
    color: #cccccc;
    vertical-align: middle;
    margin-right: 25px;
}

.record-not-found td {
    padding: 10px;
    border: none;
    font-size: 20px;
}

.record-not-found td.text-right {
    width: 20%;
}

.no-record {
    margin-top: 10%;
    margin-bottom: 1rem;
}

.cursorclass {
    cursor: pointer;
}

.closeButton {
    color: #aaaaaa;
    float: right;
    margin-right: 1%;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.modalContent {
    display: flex;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 1%;
    margin-right: 1%;
    flex-direction: column;
    align-items: center;
}

.textFieldDisabled {
    -webkit-text-fill-color: black !important;
    font-weight: bold;
}

.text-white {
    color: white;
}

.react-datepicker__input-container * {
    display: block !important;
    width: 220px !important;
    color: rgb(80, 80, 80);
    background-color: rgb(255, 255, 255);
    border-bottom-color: rgb(234, 234, 234);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(234, 234, 234);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(234, 234, 234);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(234, 234, 234);
    border-top-style: solid;
    border-top-width: 1px;
    cursor: text;
    outline-color: rgb(80, 80, 80);
    outline-style: none;
    outline-width: 0px;
    box-sizing: border-box;
    display: inline-block;
    height: 32px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    min-width: 48px;
    overflow-x: visible;
    overflow-y: visible;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 3px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.react-datepicker__month-container {
    font-size: 12px;
    width: 300px;
}

.react-datepicker__current-month {
    font-size: 12px !important;
    padding-top: 10px;
}

.react-datepicker__month {
    padding: 10px 0;
}

.react-datepicker__day-name,
.react-datepicker__day {
    margin: 10px !important;
}

.react-datepicker__month-text {
    display: inline-block;
    width: 300px;
    margin: 10px;
    font-size: 12px;
    padding: 5px;
}

.react-datepicker__navigation {
    height: 60px !important;
}

.react-datepicker-ignore-onclickoutside {
    float: left;
}

.react-datepicker__input-container > input[type="text"] {
    float: left;
}

.searchMobile {
    padding: 5px 5px 5px 5px;
    margin-bottom: 20px;
}
.fixed-header {
    flex-direction: row;
    gap: var(
        --spectrum-global-dimension-size-100,
        var(--spectrum-alias-size-100)
    );
    position: sticky;
    top: 0px;
    height: 50px;
    background-color: #fcfcfc;
    z-index: 1;
}

.search {
    width: 30%;
    padding-left: 10px;
    text-align: left;
}
._spectrum-Table-cellWrapper_d9745 {
    position: absolute;
}

._react-spectrum-Table-cellWrapper_a403a {
    position: absolute;
}

.flex-gap_7684cb {
    justify-content: end !important;
}
._spectrum-Table-cellWrapper_d9745 {
    position: absolute !important;
}
._spectrum-Table-row_d9745 {
    border-bottom: 2px solid rgb(234 234 234);
}
.rows .row {
    display: inline-block;
}

.boxBorder {
    border: 1px solid #000;
}

.react-datepicker__input-container input {
    width: 100% !important;
}

.spectrum-Tabs-itemLabel_3e140b:hover {
    cursor: pointer;
}
.schedule-label {
    min-width: 20%;
}

.spectrum-UIIcon-Magnifier_368b34 {
    display: none;
}
.spectrum-Textfield-inputIcon_73bc77 {
    padding-left: 12px !important;
}

.spectrum-Table-body_d0fc74 {
    margin-top: 5px;
    border: none;
}

.clickable {
    cursor: pointer !important;
}
.errorMessage {
    color: red;
}

.checkBoxLabelBeforeHelp {
    padding-right: 0px !important;
}

.helpContextIcon {
    margin-top: var(--spectrum-global-dimension-size-65) !important;
    margin-right: var(--spectrum-global-dimension-size-125) !important;
}

.font11 {
    font-size: 11px;
}

.alertOptionWrapperMargin {
    margin-top: 7px;
    margin-left: 5px;
}

/* Boxy Buttons */
.BoxyButton {
    height: 200px !important;
    /* width: 150px !important; */
    width: 228px !important;
    margin: 20px !important;
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(167, 164, 164) !important;
    color: rgb(167, 164, 164) !important;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.BoxyButton.active.purecloud > svg > path {
    fill: #ff4f1f;
}

.BoxyButton.active.amazonCloud > svg {
    fill: #00abba !important;
}

.BoxyButton > svg {
    fill: grey !important;
}

.active {
    border: 2px solid #3056d5 !important;
    color: black !important;
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

/* lIVE  */

.liveBlock {
    border-radius: 0px calc(var(--spectrum-focus-ring-border-radius)) 0px
        calc(
            var(--spectrum-focus-ring-border-radius) +
                var(--spectrum-focus-ring-gap)
        );
    background-color: #ff2525;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    top: 0px;
    right: 0px;
    padding: 5px 10px;
}

.card__icon {
    border-radius: 50%;
    display: block;
    width: 14px;
    height: 14px;
    background-color: #ffffff;
    transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1),
        opacity 1s cubic-bezier(0.77, 0, 0.175, 1);
    animation: live-pulse 2s infinite cubic-bezier(0.77, 0, 0.175, 1) both;
}

.card_live_text {
    font-weight: 600 !important;
    color: #ffffff;
}

@keyframes live-pulse {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}

.info-msg {
    padding: 5px !important;
    margin: 0 auto;
}

.info-msg .spectrum-InLineAlert-grid_479995 {
    grid-template: "icon content"/0fr auto !important;
    justify-content: center;
    align-items: center !important;
    column-gap: 10px !important;
}

.info-msg .spectrum-InLineAlert-content_479995 {
    margin-top: 0px !important;
}

/* Card Section for dynamic form  */
.cardSection {
    margin: 0 auto;
    padding: 10px;
    border: 1px solid thistle;
    border-radius: var(
        --spectrum-textfield-border-radius,
        var(--spectrum-alias-border-radius-regular)
    );
    display: flex;
    width: 100%;
}

.cardSection .checkboxSection {
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.cardSection .actionsSection {
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.cardSection .FormSection {
    width: 80%;
    margin: 0px 10px;
}

.cardSection .FormSection .feild {
    margin: 10px 0px;
}

.cardSection button,
.noBorder button {
    border: 0px;
    margin-left: 10px;
}

.cardSection.active {
    box-shadow: 0px 5px 5px 0px rgba(31, 81, 255, 0.3);
}

/* File upload modulu css */

/* Drag upload */
#form-file-upload {
    height: 16rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: "Oswald", sans-serif;
    background-color: transparent;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.dotttedBox {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
}

.login-btn {
    margin-top: 10px !important;
    cursor: pointer !important;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 5px; /* Adjust the width to 5px */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}

.custom-combobox *,
.custom-combobox *:hover {
    cursor: not-allowed;
}

.custom-textField > div {
    margin-left: 65px;
}

.custom-NumerField > div {
    margin-left: 70px;
}

/* This is for the placeholder of all the input fields thoughout the apllication */
.YO3Nla_spectrum-Textfield--quiet
    .YO3Nla_spectrum-Textfield-input::placeholder {
    color: #474747;
    font-size: 12px;
}

._0YML2q_spectrum-Modal--fullscreenTakeover:has(.dialogue-container) {
    margin: 65px 25px 15px !important;
}

.tabsPadding :nth-child(2) div {
    padding-left: 15px;
}

.BoxyButton:hover {
    cursor: pointer;
}

.border_box {
    border: 0.8px solid;
    border-radius: 0.7em;
    padding: 25px;
}

.mainBoxPermissionFlex {
    height: 100%;
    width: 100%;
}
.mainBoxPermissionFlex .leftsideDetails {
    width: 20%;
    border-right: 2px solid black;
    padding: 0px 10px;
}
.mainBoxPermissionFlex .rightsideDetails {
    width: 80%;
}

.taggroup > div > div > div {
    background-color: #b2b2b2;
}

.taggroup-permissions > div > div > div {
    background-color: #e5efff;
    border: none;
    padding: 8px 16px;
}

.taggroup-permissions:hover > div > div > div {
    background-color: #e5efff;
    border: none;
    padding: 8px 16px;
}

.button-white-text {
    color: white !important;
}

.longTextCss {
    overflow-wrap: "break-word";
}
.list-container {
    padding-left: 20px;
}

.list-item {
    position: relative;
    margin-bottom: 8px;
    word-wrap: break-word;
}

.list-item::before {
    content: "•";
    font-size: 2rem;
    position: absolute;
    left: -20px;
}

.list-item-success::before {
    color: #30623f;
}

.list-item-failure::before {
    color: #fa0f00;
}

.list-item-default::before {
    color: #0469e3;
}

/* Scroll view css */
/* ::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-thumb {
    background: #ebebeb;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ebebeb;
} */

/* Making Picker looks like combobox start */
.custom-picker .cm4dRG_spectrum-Dropdown-label.cm4dRG_is-placeholder {
    border-right: 1px solid #929292;
    padding: 0px 8px;
}

.cm4dRG_spectrum-Dropdown-label.cm4dRG_is-placeholder::placeholder-shown {
    color: #474747;
    font-size: 12px;
}

.custom-picker .cm4dRG_spectrum-Dropdown-label {
    border-right: 1px solid #929292;
}

.custom-picker .ntVziG_spectrum-BaseButton {
    padding: 8px;
}
/* Making Picker looks like combobox end */