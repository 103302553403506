.addBtn {
    cursor: pointer;
    /* width: auto; */
    /* padding: 0px 6px; */
    min-height: 25px;
    height: 14px;
    margin-top: 4px;
    /* border-radius: 30% !important; */
}

.checkbox {
    font-size: 12px;
    font-weight: 700;
    color: #707070;
}

.label {
    font-size: 12px;
    font-weight: 600;
    color: #707070;
}

.placeholder input::placeholder {
    font-size: 12px;
    color: #c8c3c3;
}

/* .box {
    transform: scale(1.5);
} */

.checkboxText {
    font-weight: 600;
    color: #707070;
}

.labelFont > label, .labelFont > span{ 
    font-size: 1.4rem;
}

