/* .key-margin label{
    margin-right: 47px;
}

.values-margin label{
    margin-right: 69px;
} */

.action-btn {
    float: inline-end;
}

.array > .object{
    padding-left: 20px;
    margin-left: 20px;
}

.array > .object > .feild{
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 5px;
}

.font-weight{
    font-weight: 900;
}
.center > span{
text-align: start;
}


/* Card Section for dynamic form  */
/* .configKeySection{
    margin:0 auto;
    padding: 10px;
    border: 1px solid thistle;
    border-radius: var(--spectrum-textfield-border-radius,var(--spectrum-alias-border-radius-regular));
    width:100%;
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
} */

.configKeySection{
    margin: 4px auto;
    padding: 10px;
    /* border: 1px solid thistle; */
    border-radius: var(--spectrum-textfield-border-radius,var(--spectrum-alias-border-radius-regular));
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
    cursor: pointer;
}

.cardSection .checkboxSection{
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.cardSection .actionsSection{
    margin: 0 auto;
    display: flex;
    align-items: center;
}


.cardSection .FormSection{
    width: 80%;
    margin: 0px 10px;
}

.cardSection .FormSection .feild{
    margin: 0px 0px;
}

.cardSection button, .noBorder button{
    border: 0px;
    margin-left: 10px;
}

.cardSection.active{
    box-shadow: 0px 5px 5px 0px rgba(31, 81, 255,0.3);
}

/* clone section  */

/* .configValues{ */
    /* margin-top: 10px; */
    /* padding-left: 10%;
    padding-right: 10%; */
    /* padding: 2% 0px; */
/* } */

.configKeySection section, .configKeySection hr{
    display: none;
}

.configKeySection.displayDetail {
    display: block;
    border:1px solid blue;
}

.configKeySection.displayDetail section{
    display: block;
}
.configKeySection.displayDetail .firstHr{
    display: block;
    background-color: black;
    /* margin-top: 4% 0px; */
    margin-bottom: 15px;
}

.configKeySection.displayDetail .otherHr{
    display: block;
    background-color: rgb(206, 201, 201);
    margin: 10px 0px;
}

.PBsjDW_spectrum-Dropdown-label , .Q7FggG_spectrum-Menu-itemLabel, .Ug6T-W_spectrum-Stepper.Ug6T-W_spectrum-Stepper--isQuiet .Ug6T-W_spectrum-Stepper-input{
    font-size: 12px;
}

.cardSection button, .noBorder button {
    border: 0px;
    /* margin-left: 10px; */
    padding: 0px;
    margin: 0px;
}

.Q7FggG_spectrum-Menu-item[data-key*="searchInput"]:not(.Q7FggG_is-selected) {
    display: block;
}

.Q7FggG_spectrum-Menu > div {
    --spectrum-heading-subtitle3-text-color: var(--spectrum-global-color-gray-900);
    background-color: var(--spectrum-selectlist-background-color, var(--spectrum-alias-background-color-transparent));
}